button,
.button {
  display: inline-block;
  position: relative;

  color: #651a98;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;

  padding: 6px 26px 8px 26px;

  border: none;
  background: none;

  cursor: pointer;

  span {
    z-index: 1;
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  .button-bg {
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .fill {
      fill: #ffc540;
    }
  }
}
