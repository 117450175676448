.gpx-download {
  cursor: pointer;
  margin: 0;
  padding: 6px 19px;
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  background: none;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  .btn-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
}
