@import '../../variables.scss';

.trail-detail-header {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .back-link {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 24px;
        width: 100%;
        .trail-name {
            display: flex;
            align-items: flex-end;
        }
        .link {
            max-height: 24px;
        }
        @include breakpoint-mobile() {
            margin-right: 0;
        }
    }

    .trail-name {
        font-size: 24px;
        line-height: 29px;
        font-weight: bold;
        letter-spacing: 0;
        opacity: 0.7;
        break-after: always;
        @include breakpoint-mobile() {
            font-size: 18px;
            line-height: 22px;
            opacity: 1;
        }
    }

    br {
        content: '';
        flex-basis: 100%;
    }

    .race-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 26px;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        .name-wrapper {
            flex: 60%;
        }
        .download-btn-wrapper {
            flex: 40%;
            display: flex;
            justify-content: flex-end;
        }
        h1 {
            font-size: 24px;
            line-height: 29px;
            margin-right: 14px;
        }
        @include breakpoint-mobile() {
            font-size: 36px;
            line-height: 43px;
            h1 {
                max-width: 60%;
            }
        }
    }

    .trail-length {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
    }

    .trail-length {
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        .icon {
            margin-right: 14px;
        }
    }

    @include breakpoint-mobile() {
        flex-direction: column;

        .back-link {
            position: static;
            width: auto;
            margin: 0 0 20px 0;
            transform: none;
        }

        .trail-name {
            font-size: 24px;
            line-height: 29px;
        }

        .trail-length {
            .label {
                display: inline;
                padding-right: 5px;
            }

            &:after {
                content: '';
            }
        }
    }
}
