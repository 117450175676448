$mobile-breakpoint: 415px;
$navigation-width: 375px;

@mixin breakpoint-mobile {
    @media (min-width: $mobile-breakpoint + 1) {
        @content;
    }
}

@mixin breakpoint-mobile-landscape {
    @media (min-width: 896px) {
        @content;
    }
}

:export {
    mobile-breakpoint: $mobile-breakpoint;
}
