.race-list {
  display: flex;
  flex-direction: column;

  .filters {
    padding: 16px 17px;

    flex: 0 0;

    display: flex;
    justify-content: space-between;

    .radio {
      border-radius: 14px;
      background-color: #ececec;

      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 17px;

      padding: 5px 21px 6px 21px;

      flex: 1 0;
      text-align: center;

      margin: 0 7px;

      &.checked {
        background-color: #651a98;
        color: #ffffff;
      }

      input {
        display: none;
      }
    }
  }

  .races {
    flex: 1 0;
    overflow-x: auto;
    .race {
      border-radius: 6px;
      position: relative;

      margin: 16px 24px;
      padding: 16px 16px 4px;

      background-size: cover !important;

      .race-name {
        text-decoration: none;
        color: inherit;
        h1 {
          max-width: 60%;
        }
        &:hover {
          text-decoration: underline;
        }
      }

      .date {
        opacity: 0.6;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
      }

      .live {
        color: #bd0017;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 0.39px;
        line-height: 13px;

        text-transform: uppercase;

        &:before {
          content: '\2022';
          padding: 0 2px 0 5px;
        }
      }

      .location {
        color: #53366d;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;

        &:before {
          content: '\1F4CD';
          color: #ffc540;
          padding-right: 8.5px;
        }
      }

      .race-list-virtual {
        margin-top: 10px;
      }
    }
  }
}
