.trail-list {
  padding: 0 14px;

  display: flex;
  flex-direction: column;

  .content-header {
    padding: 20px 0;
    display: flex;
    border-bottom: 1px solid #ebebeb;

    .race-location {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;

      flex: 1 0;

      .location-text {
        display: inline;
        vertical-align: middle;

        &:before {
          content: '\1F4CD';
          color: #ffc540;
          padding-right: 8.5px;
        }
      }
    }

    .location-icon {
      width: 24px;
      height: 24px;
      font-size: 23px;
      text-align: center;
      line-height: 24px;
    }

    .virtual-rece-header {
      margin: 10px;

      opacity: 0.6;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 14px;
    }
  }

  .trails {
    flex: 1 0;

    overflow-y: auto;

    .trail {
      padding: 17px 10px;
      border-bottom: 1px solid #ebebeb;

      display: flex;

      .trail-info {
        flex: 1 0;
        display: flex;
      }

      .trail-info-content {
        margin: auto 0;
      }

      .trail-name {
        margin-bottom: 5px;
      }

      .trail-detail {
        opacity: 0.6;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
      }

      .actions {
        width: 94px;

        display: flex;
        flex-direction: column;

        > :not(:first-child) {
          margin-top: 7px;
        }
      }

      .action-replay {
        color: #ffffff;

        .action-replay-bg {
          fill: #651a98;
        }
      }
    }
  }
}
