@import '../../variables.scss';

.map {
    position: relative;
    width: 100%;
    height: 100%;

    .mapboxgl-marker {
        left: 0;
        top: 0;
        position: absolute;
    }

    .circle-marker {
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }

    .race-point-container {
        position: relative;
    }

    .race-point-label {
        position: absolute;
        right: 3px;
        top: 50%;
        transform: translateX(100%) translateY(-50%);
        white-space: nowrap;

        border-radius: 2.8px;

        padding: 7px 8px 7px 10px;

        &.start-end {
            color: #651a98;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 19.6px;

            background-color: #fff;
        }

        &.checkpoint {
            right: -5px;

            color: #ffffff;
            font-size: 11.2px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 16.8px;

            background-color: #651a98;
        }
    }

    .race-point {
        width: 39px;
        height: 39px;
    }

    .checkpoint ~ .race-point {
        width: 15px;
        height: 15px;
    }

    .participant-marker {
        position: relative;

        .participant-marker-text {
            position: absolute;
            top: -38px;
            left: 19px;
            transform: translateX(-50%);
            border-radius: 6px;
            white-space: nowrap;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            line-height: 38px;
            color: #651a98;
            padding: 0 10px;
        }
    }

    .map-controls {
        position: absolute;
        bottom: 10px;
        left: 23px;
        display: flex;
        flex-direction: column;
        z-index: 11;
        &.no-graph {
            bottom: 33px;
        }
        > * {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 9px;
            background: #ffffff;
            border-radius: 50%;
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.331239);
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            &.active {
                background: #651a98;
            }
            img {
                width: 22px;
                height: 22px;
            }
        }
    }

    @include breakpoint-mobile() {
        .map-controls {
            left: 71px;
        }
        .scale-control {
            position: fixed !important;

            left: 24px !important;
            bottom: 24px !important;
        }
    }
}
