@import '../../variables.scss';

.trail-list-header {
  position: relative;
  padding: 16px 19px 15px 17px;
  margin-bottom: 36px;

  .back-link {
    margin-bottom: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .trail-name {
      display: flex;
      align-items: flex-end;
    }
    .link {
      max-height: 24px;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 13px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }

  .race-name {
    color: #ffffff;
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    h1 {
      max-width: 60%;
    }
  }

  .start-time {
    opacity: 0.5;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }

  @include breakpoint-mobile() {
    padding: 0;
  }
}
