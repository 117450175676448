.playback-controls {
  .controls {
    display: flex;
    justify-content: space-between;
    padding: 0 4px 11px 4px;
    border-radius: 4px;
    .play-controls {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      .current-speed {
        min-width: 40px;
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: .64px;
        line-height: 22px;
        color: #651a98;
        text-align: end;
      }
      .icon-button {
        height: 17px;
        img {
          height: 17px;
          width: 14px;
        }
      }
    }
    .label {
      color: #651A98;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      text-transform: uppercase;
    }
    .speed {
      opacity: 0.8;
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.64px;
      line-height: 22px;
      margin-left: auto;
      padding: 0;
      border: none;
      cursor: pointer;
      &:after {
        content: 'X';
      }
    }
  }
  .scroller {
    position: relative;
    height: 6px;
    width: 128px;
    border-radius: 9px;
    cursor: pointer;
    background-color: #DFDCE1;
    &.disabled {
      opacity: 0.4;
      .marker {
        display: none;
      }
    }
    .marker {
      position: absolute;
      height: 6px;
      width: 40px;
      border-radius: 9px;
      background-color: #651a98;
      cursor: default;
      outline: none;
    }
  }
}
