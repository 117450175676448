@import '../../variables.scss';

.race-data {
  display: flex;
  justify-content: space-between;
  .race-stat {
    text-align: left;
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.race-stats-label {
  color: #868080;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
}

.race-stats-value {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;

  &.large {
    color: #4f4e4e;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px;
  }
}

.race-stats-time {
  top: 72px;
  right: 12px;

  .time > * {
    display: block;
  }
}

.race-stats-live {
  position: fixed;
  top: 137px;
  left: 12px;

  color: #ec273f;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 17px;

  text-transform: uppercase;

  border: 2px solid #ec273f;
  border-radius: 8px;

  padding: 7px 15px 8px 16px;

  display: inline-block;

  &:before {
    content: '\2022';
    padding-right: 2px;
  }
}

.race-stats-replay-btn {
  position: fixed;
  top: 72px;
  left: 12px;
}

@include breakpoint-mobile() {
  $item-right: $navigation-width + 55px;

  .race-stats-label {
    font-size: 12px;
    line-height: 14px;
  }

  .race-stats-value {
    font-size: 12px;
    line-height: 14px;

    &.large {
      font-size: 50px;
      line-height: 60px;
    }
  }

  .race-stats-time {
    top: 142px;
    right: $item-right;

    &.ended {
      top: 24px;
    }

    .time > * {
      display: inline;
    }
  }

  .race-stats-live {
    top: 217px;
    right: $item-right;
    left: auto;
  }

  .race-stats-replay-btn {
    top: 87px;
    right: $item-right;
    left: auto;
  }
}
