@import '../../variables.scss';

@mixin separator($margin) {
    &::after {
        content: ' ';
        display: block;
        height: 1px;

        margin-top: $margin;

        opacity: 0.2;
        background-color: #969595;
    }
}

$position-width: 24px;
$profile-image-width: 30px;
$profile-image-margin: 12px;

.participant-list {
    display: flex;
    flex-direction: column;
    top: 60vh;
    &.collapsed {
        height: 182px;
        top: auto;
    }
    .control-panel {
        .controls-block {
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
            padding-top: 15px;
            .menu-button {
                position: absolute;
                right: 16px;
                margin-right: 19px;
            }
            @include breakpoint-mobile() {
                display: none;
            }
        }
    }
    .list-header {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 24px;
        padding: 0 16px;
        .age-groups-container {
            .gender-options {
                display: flex;
                justify-content: flex-start;
                margin-top: 24px;
                margin-bottom: 24px;
                font-size: 16px;
                line-height: 19px;
                color: #868080;
                *:not(:last-child) {
                    margin-right: 20px;
                }
                label > input[type="radio"] {
                    display: none;
                }
                label > input[type="radio"] + *::before {
                    content: "";
                    display: inline-block;
                    vertical-align: bottom;
                    width: 14px;
                    height: 14px;
                    margin-right: 9px;
                    border-radius: 50%;
                    border: 1px solid #868080;
                }
                label > input[type="radio"]:checked + * {
                    color: #651a98;
                }
                label > input[type="radio"]:checked + *::before {
                    background: radial-gradient(#651a98 0%, #651a98 40%, transparent 50%, transparent);
                    border-color: #651a98;
                }
                &.selected {
                    color: #302A2A;
                }
                input {
                    margin-left: 0;
                    margin-right: 9px;
                }
            }
            .age-groups-list {
                display: flex;
                align-items: center;
                padding-bottom: 8px;
                margin-bottom: 16px;
                overflow-x: auto;
                .age-group {
                    margin-right: 24px;
                    padding: 0;
                    font-size: 14px;
                    line-height: 17px;
                    color: #651a98;
                    opacity: 0.6;
                    white-space: nowrap;
                    &:last-child {
                        margin-right: 0;
                    }
                    &.active {
                        opacity: 1;
                    }
                }
                &::-webkit-scrollbar {
                    height: 3px;
                }
                &::-webkit-scrollbar-thumb {
                    border-right: 1px solid rgba(255, 255, 255, 1);
                    background: #DFDCE1;
                    border-radius: 9px;
                }
            }
        }
        .header-content {
            display: flex;
            .participant-count {
                margin-bottom: 7px;
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                color: #868080;
            }
        }
        .header-separator {
            @include separator(10px);
        }
    }
    .participants {
        flex: 1 0;
        overflow: auto;
        margin-top: 24px;
        &.no-age-group {
            margin-top: 24px;
        }
        .participant {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 0 14px;
            margin-bottom: 10px;
            text-decoration: none;
            color: inherit;
            .participant-data {
                width: 100%;
                display: flex;
            }
            .participant-column {
                width: 100%;
            }
            .participant-info {
                display: flex;
                overflow: hidden;
                .position {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: $position-width;
                    height: $position-width;
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 500;
                    border-radius: 50%;
                    color: #ffffff;
                    margin-right: 12px;
                }
                .profile-image {
                    flex-shrink: 0;
                    width: $profile-image-width;
                    height: $profile-image-width;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: $profile-image-margin;
                    .img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .name {
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 19px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .color {
                    width: 24px;
                    margin-top: 7px;
                }
            }
            .status {
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0;
                text-transform: uppercase;
                text-align: right;
                white-space: nowrap;
                overflow: hidden;
                flex-shrink: 0;
                &.finished {
                    display: flex;
                    justify-content: flex-end;
                    .icon {
                        margin-right: 9px;
                    }
                    .status-details {
                        display: flex;
                        flex-flow: column nowrap;
                        .finish-duration {
                            display: inline;
                            margin-top: 5px;
                            color: #868080;
                        }
                    }
                }
            }
            .participant-statistics {
                display: flex;
                margin-top: 17px;
                .participant-statistic {
                    display: flex;
                    flex-flow: column nowrap;
                    margin: 0 32px 0 0;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 14px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .label {
                        color: #868080;
                        margin-right: 7px;
                        white-space: nowrap;
                    }
                    .value {
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 14px;
                    }
                }
            }
        }
    }
}

@include breakpoint-mobile() {
    .list-header {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        padding: 0 21px 0 14px;

        .menu-button {
            display: none;
        }

        .header-separator {
            @include separator(14px);
        }
    }

    .participant-info {
        margin-bottom: 14px;
        .profile-image {
            width: 40px;
            height: 40px;
        }
    }

    .status {
        min-width: 92px;
        padding: 0;
        &:before,
        &:after {
            display: none;
        }
    }

    .participant-list .participants .participant .participant-statistics .participant-statistic {
        flex-flow: row nowrap !important;
        margin: 0 8px 0 0 !important;
        justify-content: space-between;
        align-items: center;

        .label {
            display: block;
        }
    }

    .participant-statistics {
        flex: 1 0;
        justify-content: space-between;
        margin: 0;

        .duration {
            display: none;
        }
    }
}

.liveWatch-placeholderImage {
    display: block;
    margin: 120px auto 20px;
}

.liveWatch-placeholderText {
    text-align: center;
    color: #d1d1d1;
    font-size: 18px;
    font-weight: 600;
}
