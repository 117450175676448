.checkbox-container {
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
  cursor: pointer;
  .styled-checkbox {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid #651A98;
    background: #FFFFFF;
    transition: all 150ms;
    &.checked {
      background: #651a98;
    }
    &:focus {
      box-shadow: 0 0 0 3px rgba(101, 26, 152, 0.35);
    }
  }
}
