@import '../../variables.scss';

.chart-container {
  position: absolute;
  bottom: 0;
  height: 210px;
  width: 100%;
  padding: 5px 12px 5px 86px;
  background: white;
  z-index: 10;
  &.has-graph {
    height: 210px;
  }
  .elevation-graph {
    height: 100% !important;
    width: 100% !important;
  }
  .chart-controls {
    position: absolute;
    bottom: 23px;
    right: 23px;
    display: flex;
    flex-direction: column;
    z-index: 11;
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px;
      background: #ffffff;
      border-radius: 50%;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.331239);
      &:first-child {
        margin-bottom: 23px;
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
}
@include breakpoint-mobile() {
  .chart-container {
    padding: 10px 24px 10px 182px;
    .chart-controls {
      right: 71px;
    }
  }
}
