@import './variables.scss';

$logo-z-index: 999;

html,
body,
#root,
.container {
    height: 100%;
}

body * {
    box-sizing: border-box;
}

body {
    overflow: hidden;
}

.container {
    position: relative;
}

.user-row {
    padding-left: 17px;
    float: left;
    width: 170px;
    margin-top: 5px;

    p {
        margin: 2px 0 0 0;
        font-size: 12px;
        color: rgba(53, 52, 52, 0.87);
        opacity: 0.6;
    }
}

h1 {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 38px;

    margin: 0;
    padding: 0;
}

h2 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;

    margin: 0;
    padding: 0;
}

.app-logo {
    display: none;
}

.map-container {
    position: fixed;
    width: 100vw;
    height: 300px;
}

.navigation-header {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;

    background-color: #651a98;
    color: #ffffff;
    padding: 8px 12px 9px 16px;

    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24);
}

.navigation-content {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vw;
    overflow: hidden;
    background-color: #ffffff;
    color: #302A2A;
    & > *::-webkit-scrollbar {
        width: 9px;
    }
    &> *::-webkit-scrollbar-thumb {
        border-right: 1px solid rgba(255,255,255,1);
        background: #DFDCE1;
        border-radius: 9px;
    }
}

.race-stats {
    text-align: right;
}

.playback {
    width: auto;
}

@include breakpoint-mobile() {
    .map-container {
        width: calc(100vw - 375px);
        right: 0;
        left: 0;
    }

    .navigation {
        position: absolute;
        top: 24px;
        right: 24px;
        bottom: 24px;
        z-index: 1;

        width: $navigation-width;

        .navigation-header {
            padding: 24px;
        }
    }

    .navigation-header,
    .navigation-content {
        width: $navigation-width;
    }

    .navigation-header {
        padding: 24px 24px 34px 28px;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    }

    .navigation-content {
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    }
}

@include breakpoint-mobile-landscape() {
    .app-logo {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 375px);
        background: #ffffff;
        z-index: $logo-z-index;
    }
}
