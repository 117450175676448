.virtual-race-label {
  display: inline-block;
  padding: 7px 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0;
  text-transform: uppercase;
  border-radius: 30px;
  &.virtual {
    background-color: #ffc540;
    color: #4f4e4e;
  }
  &.challenge {
    background-color: #2688d1;
    color: #ffffff;
  }
}
