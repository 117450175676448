@import '../../variables.scss';

.race-list-header {
  .logo-container {
    display: flex;
    padding: 28px 17px;

    .logo {
      height: 40px;
      margin-right: 17px;
    }

    .racelog {
      height: 30px;
      margin-top: 5px;
    }
  }

  .title {
    padding: 38px 0;
  }

  @include breakpoint-mobile-landscape() {
    .logo-container {
      display: none;
    }
  }
}
