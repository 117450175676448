.icon-button {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  &:focus {
    outline: none;
  }
  &.disabled {
    opacity: 0.7;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
