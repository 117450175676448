.logo-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 64px;
  .logo {
    height: 20px;
    margin-right: 18px;
  }
  .racelog {
    height: 20px;
    margin-top: 5px;
  }
}
